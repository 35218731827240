import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

function Index() {
  useEffect(() => {
    navigate('/brands');
  }, []);

  return <div></div>;
}

export default Index;
